import React from "react";
import { graphql } from "gatsby";
const IndexPage = ({}) => {
  return <div></div>;
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
